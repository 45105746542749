<template>
	<div class="dataMiddleground">
		<applyFrom @close="close" v-if="applyFromshow"></applyFrom>
		<div class="banner">
			<div class="banner-body flex">
				<div class="banner-body-title flex">
					<div style="width: 55%;align-items: initial;flex-direction: column;" class="flex">
						<span style="font-size: 0.36rem; color: #FFFFFF;font-family: Source Han Sans CN;">数据中台</span>
						<span
							style="font-size: 0.17rem;color: #fff;font-family: Source Han Sans CN;margin-top: 0.30rem;">企业数据资产价值最大化</span>
						<div class="sqsy" @click="applyFromshow = true">申请试用</div>
					</div>
				</div>
				<div class="banner-body-img flex">
					<img style="width: 2.67rem;" src="../assets/img/dataMiddleground-banner.png">
				</div>
			</div>
			<img style="width: 100%;height: 100%;" src="../assets/img/Journalismbanner.png">
		</div>

		<div class="characteristic">
			<div class="characteristic-tit">
				<div class="worry-tit">
					<span class="worry-tit-t">产品特色</span><br />
				</div>
			</div>

			<div class="characteristic-box flex">
				<div class="characteristic-box-cell flex">
					<div style="height: 0.70rem;">
						<img style="width: 0.47rem;" src="../assets/img/dataMiddleground-characteristic1.png">
					</div>
					
					<div
						style="color: #333333;font-size: 0.14rem;font-weight: 400;font-family: Source Han Sans CN;">
						统一数据标准</div>
					<div
						style="color: #999999;font-size: 0.09rem;font-weight: 400;font-family: Source Han Sans CN;margin-top: 0.20rem;">
						统一数据指标口径，消除数据二义性，形成企业特有的数据标准，实现数据的融合管理。
					</div>
				</div>
				<div class="characteristic-box-cell flex">
					
					<div style="height: 0.70rem;">
						<img style="width: 0.47rem;" src="../assets/img/dataMiddleground-characteristic2.png">
					</div>
					<div
						style="color: #333333;font-size: 0.14rem;font-weight: 400;font-family: Source Han Sans CN;">
						统一数据资产</div>
					<div
						style="color: #999999;font-size: 0.09rem;font-weight: 400;font-family: Source Han Sans CN;margin-top: 0.20rem;">
						深度挖掘企业数据，全链路数 据集成，最终构建企业统一的 数据资产。
					</div>
				</div>
				<div class="characteristic-box-cell flex">
					<div style="height: 0.70rem;">
						<img style="width: 0.47rem;" src="../assets/img/dataMiddleground-characteristic3.png">
					</div>
					<div
						style="color: #333333;font-size: 0.14rem;font-weight: 400;font-family: Source Han Sans CN;">
						多类型数据模型</div>
					<div
						style="color: #999999;font-size: 0.09rem;font-weight: 400;font-family: Source Han Sans CN;margin-top: 0.20rem;">
						搭建全方位的数据模型，涉及用户、商品、交易、言销、渠道、物流等主题域。
					</div>
				</div>
				<div class="characteristic-box-cell flex">
					<div style="height: 0.70rem;">
						<img style="width: 0.47rem;" src="../assets/img/dataMiddleground-characteristic4.png">
					</div>
					<div
						style="color: #333333;font-size: 0.14rem;font-weight: 400;font-family: Source Han Sans CN;">
						多类型数据模型</div>
					<div
						style="color: #999999;font-size: 0.09rem;font-weight: 400;font-family: Source Han Sans CN;margin-top: 0.20rem;">
						搭建全方位的数据模型，涉及用户、商品、交易、言销、渠道、物流等主题域。
					</div>
				</div>
				<!-- <div class="characteristic-box-cell flex">
					<div style="height: 0.80rem;">
						<img style="width: 0.47rem;" src="../assets/img/dataMiddleground-characteristic4.png">
					</div>
					<div
						style="color: #333333;font-size: 0.14rem;font-weight: 400;font-family: Source Han Sans CN;">
						易操作的工具平台</div>
					<div
						style="color: #999999;font-size: 0.09rem;font-weight: 400;font-family: Source Han Sans CN;margin-top: 0.20rem;">
						深度挖掘企业数据，全链路数据集成，最终构建企业统的数据资产。
					</div>
				</div> -->
			</div>
		</div>

		<div class="framework">
			<div class="characteristic-tit">
				<div class="worry-tit">
					<span class="worry-tit-t">数据中台整体架构</span><br />
				</div>
			</div>

			<div class="framework-box">
				<img style="width: 100%;" src="../assets/img/dataMiddleground-framework.png">
			</div>
		</div>

		<div class="charafunction">
			<div class="characteristic-tit">
				<div class="worry-tit">
					<span class="worry-tit-t">功能特性</span><br />
				</div>
			</div>

			<div class="charafunction-box">
				<div class="charafunction-box-cell flex" v-for="(item,index) in charafunctionlist1">
					<div style="width: 50%;">
						<img style="width: 2.30rem;" :src="item.img">
					</div>

					<div class="charafunction-box-cellR" style="margin-left: 0.60rem;">
						<div class="charafunction-box-cellR-tit">{{item.title}}</div>
						<div class="charafunction-box-cellR-lab">{{item.lable}}</div>
						<div class=" charafunction-box-cellR-con flex">
							<div style="width: 40%;justify-content: flex-start;margin-bottom: 0.10rem;" class="flex"
								v-for="i in item.list">
								<img style="width: 0.10rem;" src="../assets/img/dataMiddleground-charafunction-icon.png">
								<div class="charafunction-box-cellR-con-span">{{i}}</div>
							</div>
						</div>
					</div>
				</div>

				<div class="charafunction-box-cell flex" v-for="(item,index) in charafunctionlist2">
					<div class="charafunction-box-cellR" style="margin-right: 0.60rem;">
						<div class="charafunction-box-cellR-tit">{{item.title}}</div>
						<div class="charafunction-box-cellR-lab">{{item.lable}}</div>
						<div class=" charafunction-box-cellR-con flex">
							<div style="width: 40%;justify-content: flex-start;margin-bottom: 10px;" class="flex"
								v-for="i in item.list">
								<img style="width: 0.10rem;" src="../assets/img/dataMiddleground-charafunction-icon.png">
								<div class="charafunction-box-cellR-con-span">{{i}}</div>
							</div>
						</div>
					</div>

					<div style="width: 50%;text-align: right;">
						<img style="width: 2.30rem;" :src="item.img">
					</div>
				</div>

				<div class="charafunction-box-cell flex" v-for="(item,index) in charafunctionlist3">
					<div style="width: 50%;">
						<img style="width: 2.30rem;" :src="item.img">
					</div>

					<div class="charafunction-box-cellR" style="margin-left: 0.60rem;">
						<div class="charafunction-box-cellR-tit">{{item.title}}</div>
						<div class="charafunction-box-cellR-lab">{{item.lable}}</div>
						<div class=" charafunction-box-cellR-con flex">
							<div style="width: 40%;justify-content: flex-start;margin-bottom: 0.10rem;" class="flex"
								v-for="i in item.list">
								<img style="width: 0.10rem;" src="../assets/img/dataMiddleground-charafunction-icon.png">
								<div class="charafunction-box-cellR-con-span">{{i}}</div>
							</div>
						</div>
					</div>
				</div>

				<div class="charafunction-box-cell flex" v-for="(item,index) in charafunctionlist4">
					<div class="charafunction-box-cellR" style="margin-right: 0.60rem;">
						<div class="charafunction-box-cellR-tit">{{item.title}}</div>
						<div class="charafunction-box-cellR-lab">{{item.lable}}</div>
						<div class=" charafunction-box-cellR-con flex">
							<div style="width: 40%;justify-content: flex-start;margin-bottom: 0.10rem;" class="flex"
								v-for="i in item.list">
								<img style="width: 0.10rem;" src="../assets/img/dataMiddleground-charafunction-icon.png">
								<div class="charafunction-box-cellR-con-span">{{i}}</div>
							</div>
						</div>
					</div>

					<div style="width: 50%;text-align: right;">
						<img style="width: 2.30rem;" :src="item.img">
					</div>
				</div>

				<div class="charafunction-box-cell flex" v-for="(item,index) in charafunctionlist5">
					<div style="width: 50%;">
						<img style="width: 2.00rem;" :src="item.img">
					</div>

					<div class="charafunction-box-cellR" style="margin-left: 0.60rem;">
						<div class="charafunction-box-cellR-tit">{{item.title}}</div>
						<div class="charafunction-box-cellR-lab">{{item.lable}}</div>
						<div class=" charafunction-box-cellR-con flex">
							<div style="width: 40%;justify-content: flex-start;margin-bottom: 0.10rem;" class="flex"
								v-for="i in item.list">
								<img style="width: 0.10rem;" src="../assets/img/dataMiddleground-charafunction-icon.png">
								<div class="charafunction-box-cellR-con-span">{{i}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="platform">
			<div class="characteristic-tit">
				<div class="worry-tit">
					<span class="worry-tit-t">数据中台整体架构</span><br />
					<span class="worry-tit-b">集团数字化全业务流程，快速实现企业业务创新</span>
				</div>
			</div>

			<div class="platform-box flex">
				<div class="platform-box-cell" v-for="item in platformlist">
					<img style="width: 0.45rem;" :src="item.img">
					<div class="platform-box-cell-span">{{item.title}}</div>
				</div>
			</div>
		</div>
		
		<div class="advantage">
			<div class="characteristic-tit" style="width: 50%;margin: 0 auto;text-align: left;">
				<div class="worry-tit">
					<span class="worry-tit-t">业务优势</span><br />
				</div>
			</div>
			
			<div class="advantage-box">
				<div class="flex" style="width: 100%;justify-content: initial;margin-bottom: 0.20rem;">
					<img style="width: 0.10rem;" src="../assets/img/dataMiddleground-charafunction-icon.png">
					<div class="charafunction-box-cellR-con-span">覆盖企业数字化营销所有的业务场景，覆盖人、货、场的所有业务能力</div>
				</div>
				<div class="flex" style="width: 100%;justify-content: initial;margin-bottom: 0.20rem;">
					<img style="width: 0.10rem;" src="../assets/img/dataMiddleground-charafunction-icon.png">
					<div class="charafunction-box-cellR-con-span">多端支持，覆盖H5、小程序、APP等多个平台</div>
				</div>
			</div>
		</div>
		
		<div class="advantage">
			<div class="characteristic-tit" style="width: 50%;margin: 0 auto;text-align: left;">
				<div class="worry-tit">
					<span class="worry-tit-t">技术优势</span><br />
				</div>
			</div>
			
			<div class="advantage-box">
				<div class="flex" style="width: 100%;justify-content: initial;margin-bottom: 0.20rem;">
					<img style="width: 0.10rem;" src="../assets/img/dataMiddleground-charafunction-icon.png">
					<div class="charafunction-box-cellR-con-span">基于业务中台和微服务的技术架构，支撑亿级并发访问</div>
				</div>
				<div class="flex" style="width: 100%;justify-content: initial;margin-bottom: 0.20rem;">
					<img style="width: 0.10rem;" src="../assets/img/dataMiddleground-charafunction-icon.png">
					<div class="charafunction-box-cellR-con-span">高可靠，毫秒级延时的消息服务</div>
				</div>
				<div class="flex" style="width: 100%;justify-content: initial;margin-bottom: 0.20rem;">
					<img style="width: 0.10rem;" src="../assets/img/dataMiddleground-charafunction-icon.png">
					<div class="charafunction-box-cellR-con-span">服务安全分级，根据不同业务提供不同的能力</div>
				</div>
				<div class="flex" style="width: 100%;justify-content: initial;margin-bottom: 0.20rem;">
					<img style="width: 0.10rem;" src="../assets/img/dataMiddleground-charafunction-icon.png">
					<div class="charafunction-box-cellR-con-span">数据异地灾备，保障数据完整性</div>
				</div>
			</div>
		</div>
		
		<div class="programme">
			<div class="programme-tit">全渠道数据中台联动，让数据更有价值</div>
			<div class="programme-but flex">
				<div class="programme-but-input">想了解更多产品信息？</div>
				<div class="programme-but-but flex" @click="applyFromshow = true">点击申请</div>
			</div>
		</div>
	</div>
</template>

<script>
	import applyFrom from '@/components/applyFrom.vue';
	export default {
		data() {
			return {
				applyFromshow: false,
				charafunctionlist1: [{
					img: require('../assets/img/dataMiddleground-charafunction1.png'),
					title: '数据资产管理',
					lable: '整合全域业务数据，规范化数据建模，建设企业数据资产。',
					list: ['消费者数据', '物流数据', '商品数据', '财务数据', '订单数据', '营销数据', '组织数据', '销售数据', '库存数据', '服务数据']
				}],
				charafunctionlist2: [{
					img: require('../assets/img/dataMiddleground-charafunction2.png'),
					title: '数据场景化应用',
					lable: '在不同场景下，发挥数据的不同价值，满足企业多场景的经营需求。',
					list: ['营销人群匹配', '促销活动', '商品精准定价', '用户价值评估模型', '商品销量预测', '产销协同', '库存/物流优化']
				}],
				charafunctionlist3: [{
					img: require('../assets/img/dataMiddleground-charafunction3.png'),
					title: '数据智能决策',
					lable: '依托大数据，提供科学的模型评估方法，智能推荐最佳模型， 助力企业精准决策。',
					list: ['商品推荐', '个性化推荐（千人千面/一人千面）', '最佳模型智能推荐', '门店智能选址']
				}],
				charafunctionlist4: [{
					img: require('../assets/img/dataMiddleground-charafunction4.png'),
					title: '运营管理分析',
					lable: '多维度数据精准分析，提供精准的企业运营依据。',
					list: ['进销存分析', '口碑分析', '财务分析', '画像分析', '售后分析', '标签分析', '人力分析', '门店分析']
				}],
				charafunctionlist5: [{
					img: require('../assets/img/dataMiddleground-charafunction5.png'),
					title: '可视化报表呈现',
					lable: '自由布局分析报告，进行各类可视化的分析操作，数据即时洞察， 不仅仅是报表可视化，更是数据问题的可视化。',
					list: ['自定义报表生成', '自助报告分析', '数据大屏', '数据看板', '集团监控', '成本优化']
				}],

				platformlist: [{
						img: require('../assets/img/dataMiddleground-platform1.png'),
						title: '客户API'
					},
					{
						img: require('../assets/img/dataMiddleground-platform2.png'),
						title: '订单API'
					},
					{
						img: require('../assets/img/dataMiddleground-platform3.png'),
						title: '营销API'
					},
					{
						img: require('../assets/img/dataMiddleground-platform4.png'),
						title: '仓储API'
					},
					{
						img: require('../assets/img/dataMiddleground-platform5.png'),
						title: '销售API'
					},
					{
						img: require('../assets/img/dataMiddleground-platform6.png'),
						title: '物流API'
					},
					{
						img: require('../assets/img/dataMiddleground-platform7.png'),
						title: '调度API'
					},
					{
						img: require('../assets/img/dataMiddleground-platform8.png'),
						title: '商品API'
					},
					{
						img: require('../assets/img/dataMiddleground-platform9.png'),
						title: '支付API'
					},
					{
						img: require('../assets/img/dataMiddleground-platform10.png'),
						title: '店铺API'
					},
					{
						img: require('../assets/img/dataMiddleground-platform11.png'),
						title: '渠道API'
					},
					{
						img: require('../assets/img/dataMiddleground-platform12.png'),
						title: '财务API'
					}
				]
			}
		},
		components: {
			// Canvas，
			applyFrom
		},
		methods:{
			close(){
				this.applyFromshow = false;
			},
		}
	}
</script>

<style lang="scss" scoped>
	@media screen and (max-width:750px) {
		.charafunction-box{
			width: 80% !important;
		}
		.characteristic-box{
			width: 90% !important;
		}
		
		.platform-box{
			width: 90% !important;
		}
		
		.characteristic-tit{
			width: 80% !important;
		}
		.advantage-box{
			width: 80% !important;
		}
	}
	.banner {
		width: 100%;
		height: 3.00rem;
		// padding-top: 30px;
		position: relative;
		z-index: 101;
		.banner-body {
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			right: 0;

			// padding: 40px 0;
			.banner-body-img {
				width: 35%;
				height: 100%;
				text-align: left;
				justify-content: initial;
				position: relative;
			}

			.banner-body-title {
				width: 44%;
				height: 100%;
				// flex-direction: column;
				text-align: left;
				// justify-content: right;

				// align-items: initial;
				.sqsy {
					width: 1.80rem;
					height: 0.5rem;
					border-radius: 6px;
					// margin: 0 auto;
					margin-top: 0.20rem;
					background-image: url(../assets/img/sqsy.png);
					background-repeat: no-repeat;
					font-size: 0.25rem;
					text-align: center;
					line-height: 0.50rem;
					font-size: 0.24rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #FFFFFF;
				}
			}
		}

		// background-image: url(../assets/img/Journalismbanner.png);
	}

	.characteristic {
		width: 100%;
		padding: 0.60rem 0;

		.characteristic-box {
			width: 50%;
			margin: 0 auto;
			margin-top: 0.60rem;
			justify-content: space-between;

			.characteristic-box-cell {
				width: 19%;
				flex-direction: column;
				// margin-right: 1%;
			}
		}
	}

	.framework {
		width: 100%;
		padding: 0.60rem 0;
		background-color: #f7f7f7;


		.framework-box {
			max-width: 12.39rem;
			margin: 0 auto;
			margin-top: 0.60rem;
		}
	}


	.characteristic-tit {
		width: 100%;
		text-align: center;
		line-height: 0.30rem;

		.worry-tit-t {
			font-size: 0.27rem;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #333;
		}

		.worry-tit-b {
			font-size: 0.12rem;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #666;
		}
	}

	.charafunction {
		width: 100%;
		padding: 0.60rem 0;

		.charafunction-box {
			width: 40%;
			margin: 0 auto;
			margin-top: 0.60rem;

			.charafunction-box-cell {
				width: 100%;
				align-items: flex-start;
				text-align: left;
				margin-bottom: 0.60rem;

				.charafunction-box-cellR {
					flex: 1;

					.charafunction-box-cellR-tit {
						font-size: 0.20rem;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #333333;
					}

					.charafunction-box-cellR-lab {
						font-size: 0.12rem;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #666666;
						margin-top: 0.20rem;
					}

					.charafunction-box-cellR-con {
						width: 100%;
						margin-top: 0.20rem;
						text-align: left;
						justify-content: space-between;
						flex-wrap: wrap;

						.charafunction-box-cellR-con-span {
							font-size: 0.11rem;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #999999;
							margin-left: 0.10rem;
						}
					}
				}
			}
		}
	}

	.platform {
		width: 100%;
		padding: 0.60rem 0;

		.platform-box {
			width: 50%;
			margin: 0 auto;
			margin-top: 0.60rem;
			flex-wrap: wrap;
			justify-content: flex-start;
			.platform-box-cell {
				width: 20%;
				padding: 0.40rem 0;

				.platform-box-cell-span {
					font-size: 0.15rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #333333;
					margin-top: 0.20rem;
				}
			}
		}
	}
	
	.advantage{
		width: 100%;
		padding: 0.40rem 0;
		.characteristic-tit{
			font-size: 0.20rem;
		}
		
		.advantage-box{
			width: 50%;
			margin: 0 auto;
			text-align: left;
			margin-top: 0.40rem;
			.charafunction-box-cellR-con-span{
				font-size: 0.11rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #999999;
				margin-left: 0.10rem;
			}
		}
	}
	
	.programme{
		width: 100%;
		height: 2.00rem;
		background-image: url(../assets/img/dg-bg.png);
		.programme-tit{
			width: 100%;
			text-align: center;
			font-size: 0.27rem;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #FFFFFF;
			padding: 0.40rem 0;
		}
		
		.programme-but{
			// width: 50%;
			margin: 0 auto;
			.programme-but-input{
				// width: 320px;
				height: 0.45rem;
				border: 1px solid #ccc;
				font-size: 0.15rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 0.45rem;
				padding: 0 0.30rem;
			}
			.programme-but-but {
				width: 1.50rem;
				height: 0.45rem;
				font-size: 0.20rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
				margin-left: 0.60rem;
				border-radius: 5px;
				background: linear-gradient(90deg, #2E89F9, #06D8EE);
			}
		}
	}
</style>
